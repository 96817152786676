import Vue from 'vue';
import App from './App.vue';
import router from './router';
import liff from '@line/liff';

Vue.config.productionTip = false;

const init = async function(liffId) {
  await liff.init({
    liffId,
  });

  if (!liff.isInClient()) {
    alert("請回到手機 LINE APP 中進行操作");
    window.location = 'https://lin.ee/X7jC6Ai';
    return;
  }

  if (!liff.isLoggedIn()) {
    liff.login();
    return;
  }

  try {
    const profile = await liff.getProfile();
    return profile;
  } catch (error) {
    console.log(error);
  }
};

init('1471708768-4WqYKWJL').then(profile => {
  new Vue({
    router,
    render: h => h(App),
    data: {
      profile: profile,
    },

    methods: {
      shareTargetPicker: liff.shareTargetPicker,
      closeWindow: liff.closeWindow,
    },
  }).$mount('#app')
});
