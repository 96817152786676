<template>
  <div>
    <h4>卡友身份認證中</h4>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  async mounted() {
    const code = this.$route.query.code;
    const url = `${process.env.VUE_APP_API_URL}/accept`;

    try {
      await axios.post(url, {
        uid: this.$root.profile.userId,
        name: this.$root.profile.displayName,
        code,
      });
    } catch (error) {}
    this.$root.closeWindow();
  },
}
</script>

<style lang="scss" scoped>
div {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  
  h4 {
    margin: 0;
  }
}
</style>