<template>
  <div>
    <h4>{{ isExpired ? '已超過邀請時間，無法邀請' : '卡友身份認證中'}}</h4>
  </div>
</template>

<script>
import axios from 'axios';
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Asia/Taipei");

export default {
  computed: {
    isExpired() {
      return dayjs().diff('2022-10-28 23:59:59', 'second') > 0;
    }
  },

  async mounted() {
    if (this.isExpired) {
      this.$root.closeWindow();
      return;
    }

    const url = `${process.env.VUE_APP_API_URL}/invite`;
    try {
      const { data } = await axios.post(url, {
        uid: this.$root.profile.userId,
      });

      await this.$root.shareTargetPicker([{
        "type": "flex",
        "altText": "邀請你一起來IKEA Live House",
        "contents": {
          "type": "bubble",
          "hero": {
            "type": "image",
            "url": "https://www.ikea.com.tw/dairyfarm/tw/uploader/image/1665553449_721.jpg",
            "size": "full",
            "aspectRatio": "20:13",
            "aspectMode": "cover"
          },
          "body": {
            "type": "box",
            "layout": "vertical",
            "contents": [
              {
                "type": "text",
                "text": "邀請你一起來IKEA Live House",
                "weight": "bold",
                "size": "md",
                "align": "center"
              },
              {
                "type": "box",
                "layout": "vertical",
                "margin": "lg",
                "spacing": "sm",
                "contents": [
                  {
                    "type": "box",
                    "layout": "baseline",
                    "spacing": "sm",
                    "contents": [
                      {
                        "type": "text",
                        "text": `${this.$root.profile.displayName}邀請你11/2(三)一起來IKEA新莊店聽告五人、家家唱歌，請於10/28(五)23:59前按下下方「接受邀請」，手腳慢了就不等人了`,
                        "wrap": true,
                        "color": "#666666",
                        "size": "sm",
                        "flex": 5
                      }
                    ]
                  },
                  {
                    "type": "box",
                    "layout": "vertical",
                    "contents": [
                      {
                        "type": "box",
                        "layout": "baseline",
                        "contents": [
                          {
                            "type": "text",
                            "text": "小提醒：點選接受邀請後記得到IKEA官方帳號查看邀請結果",
                            "color": "#666666",
                            "flex": 5,
                            "size": "sm",
                            "wrap": true
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          "footer": {
            "type": "box",
            "layout": "vertical",
            "spacing": "sm",
            "contents": [
              {
                "type": "button",
                "style": "link",
                "height": "sm",
                "action": {
                  "type": "uri",
                  "label": "接受邀請",
                  "uri": data.url,
                }
              }
            ],
            "flex": 0
          }
        }
      }]);
    } catch (error) {}
    this.$root.closeWindow();
  },
}
</script>

<style lang="scss" scoped>
div {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  
  h4 {
    margin: 0;
  }
}
</style>