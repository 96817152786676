<template>
  <div>
    <h4>活動已截止報名</h4>
  </div>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => {
      this.$root.closeWindow();
    }, 300);
  },
}
</script>

<style lang="scss" scoped>
div {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  
  h4 {
    margin: 0;
  }
}
</style>