<template>
  <div>
    <h4>卡友身份認證中</h4>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  async mounted() {
    const url = `${process.env.VUE_APP_API_URL}/menu`;
    try {
      await axios.post(url, {
        uid: this.$root.profile.userId,
      });
    } catch (error) {};
    location.href = 'https://maac.io/1RehR';
  },
}
</script>

<style lang="scss" scoped>
div {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  
  h4 {
    margin: 0;
  }
}
</style>