import Vue from 'vue'
import VueRouter from 'vue-router'
import Register from '../views/Register';
import Invite from '../views/Invite';
import Accept from '../views/Accept';
import Menu from '../views/Menu';
import MenuBack from '../views/MenuBack';

Vue.use(VueRouter)

const routes = [
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/invite',
    name: 'invite',
    component: Invite,
  },
  {
    path: '/accept',
    name: 'accept',
    component: Accept,
  },
  {
    path: '/check-in',
    name: 'menu',
    component: Menu,
  },
  {
    path: '/menu-back',
    name: 'menu-back',
    component: MenuBack,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
